import { Trans } from 'next-i18next';
import React from 'react';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import FormatTime from '@/components/atoms/FormatTime';
import { ArrowSyncAlt, Search } from '@/icons';

interface SearchBoxCompactProps {
  fromLocation: string;
  toLocation: string;
  departureDate: string;
  returnDepartureDate: string;
  passengers: string;
  setShowFullBox: React.Dispatch<React.SetStateAction<boolean>>;
}
const SearchBoxCompact: React.FC<SearchBoxCompactProps> = ({
  fromLocation,
  toLocation,
  departureDate,
  returnDepartureDate,
  passengers,
  setShowFullBox,
}) => (
  <ClickableDiv
    onClick={() => setShowFullBox(true)}
    className="lg:hidden mb-2.5 px-2 pt-1 pb-2 bg-primary-yellow"
  >
    <div className="mb-1 px-1 h-4 flex justify-between items-center rounded-sm bg-white">
      <div className="w-11/12 flex items-center text-14">
        <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{fromLocation}</div>
        <ArrowSyncAlt className="w-2.5 h-2.5 mx-1.5 flex-shrink-0" />
        <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{toLocation}</div>
      </div>
      <Search className="w-3 h-3 flex-shrink-0 fill-neutral-gray" />
    </div>
    <div className="flex justify-between items-center text-13">
      <div>
        <FormatTime date={departureDate} formatType="SHORT_DATE" />
        {returnDepartureDate && (
          <>
            - <FormatTime date={returnDepartureDate} formatType="SHORT_DATE" />
          </>
        )}
        , {passengers}
      </div>
      <div className="text-primary-blue">
        <Trans i18nKey="ticket.passengers.edit" />
      </div>
    </div>
  </ClickableDiv>
);

export default SearchBoxCompact;
