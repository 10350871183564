import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { useLocalStorage } from 'react-use';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import { BookingContext, useLocations, useSearchLocations, utils } from '@web/shop-logic';
import { LocationStation } from '@web/shop-logic/dist/hooks/useLocations';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import GeolocationNotAllowed from '@/components/atoms/GeolocationNotAllowed';
import SelectDropdown from '@/components/atoms/SelectDropdown';
import useModal from '@/components/modal/useModal';
import { Menu, NoOptionMessage, Option } from '@/components/organisms/CitiesSelectComponents';
import PopoverHeader from '@/components/organisms/PopoverHeader';
import useContextSelector from '@/hooks/useContextSelector';
import useMobileWidth from '@/hooks/useMobileWidth';
import { LocationOn, DestinationFrom, ArrowSyncAlt } from '@/icons';
import {
  City,
  CitySelectProps,
  HandleCityFnc,
  NearestStationsProps,
  CitiesOptionValues,
  mapDestinationToOption,
} from '@/utils/citiesSelectUtils';

interface UserCoordinates {
  userLatitude: number;
  userLongitude: number;
}

const action: ActionMeta<CitiesOptionValues> = {
  action: 'select-option',
  option: undefined,
  name: undefined,
};

const CitiesSelect: React.FC<CitySelectProps> = ({
  handleSelectFrom,
  handleSelectTo,
  isOpenFrom,
  isOpenTo,
  onFromChange,
  onToChange,
  defaultValues,
  strapiFavorites,
}) => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const { isMobile } = useMobileWidth();
  const { loading, getDestination, data } = useLocations();
  const favorites = useContextSelector(BookingContext, (c) => c.state.fav, []);

  const [searchString, setSearchString] = useState<string>('');
  const [from, setFrom] = useState<City | null>(defaultValues?.from || null);
  const [to, setTo] = useState<City | null>(defaultValues?.to || null);
  const [anchorElFrom, setAnchorElFrom] = useState(null);
  const [anchorElTo, setAnchorElTo] = useState(null);

  const { shouldSearch, filtered: filteredPlain } = useSearchLocations(searchString);

  const [isFourNearestStationsOnMobile, setIsFourNearestStationsOnMobile] = useState(false);
  const [isInputInMobileSearchBar, setIsInputInMobileSearchBar] = useState(false);
  const [isGeolocation, setIsGeolocation] = useState(false);

  const [closestStations, setClosestStationsToLocalStorage] = useLocalStorage<NearestStationsProps>(
    'closest-stations',
    { label: '', options: [] },
  );
  const [{ userLatitude, userLongitude }, setUserLocationToLocalStorage] =
    useLocalStorage<UserCoordinates>('user-location', { userLatitude: 0, userLongitude: 0 });

  const isTouchDevice = !!global.window?.ontouchstart;
  const { showModal } = useModal();

  const filteredStations = useMemo(
    () =>
      filteredPlain.flatMap((city) => [
        mapDestinationToOption(city),
        ...city.stations.map(mapDestinationToOption),
      ]),
    [filteredPlain],
  );

  const lastSearch = useMemo(() => {
    /**
     * Kdyz se nenactou lokace, nedava smysl, vratime prazdne pole
     */

    if (loading) return [];

    const transformedStrapiFavorites =
      strapiFavorites?.map((fav) => ({
        fromLocationId: +fav.fromLocationId,
        toLocationId: +fav.toLocationId,
      })) || [];

    const uniquePairs = new Set();

    const uniqueStations = [...favorites, ...transformedStrapiFavorites].filter((station) => {
      const pairKey = `${station.fromLocationId}-${station.toLocationId}`;
      if (!uniquePairs.has(pairKey)) {
        uniquePairs.add(pairKey);
        return true;
      }
      return false;
    });

    return uniqueStations
      .slice(0, 4)
      .map((favorite) => ({
        from: getDestination(favorite.fromLocationId),
        to: getDestination(favorite.toLocationId),
      }))
      .filter((fav) => fav.from && fav.to);
  }, [loading, favorites, strapiFavorites]);

  const closestOption = {
    label: t('stationDepartures.closeststation'),
    getLocationTitle: closestStations.label
      ? closestStations.options[0]?.label
      : t('stationDepartures.getLocation'),
    value: 'closestOption',
  };

  const lastSearchedOptions = {
    label: t('station.favourite.connection.header'),
    options: lastSearch.map((search, index) => ({
      label: `${(search.from as LocationStation).fullname || search.from.name} ⟶ ${
        (search.to as LocationStation).fullname || search.to.name
      }`,
      placeType: 'CITY',
      value: `fav${index}`,
      stationIds: {
        from: search.from.id,
        to: search.to.id,
      },
    })),
  };

  const filteredStationsMob = useMemo(() => {
    if (shouldSearch) {
      return {
        from: filteredStations,
        to: filteredStations,
      };
    }
    return {
      from:
        isGeolocation && closestStations.options.length && isFourNearestStationsOnMobile
          ? [closestStations]
          : [lastSearchedOptions],
      to: [],
    };
  }, [
    shouldSearch,
    filteredStations,
    anchorElFrom,
    anchorElTo,
    !isGeolocation,
    isFourNearestStationsOnMobile,
    !!lastSearch.length,
    closestStations,
  ]);

  const filteredStationsDesktop = useMemo(() => {
    return {
      from: shouldSearch ? [, ...filteredStations] : [closestOption, lastSearchedOptions],
      to: shouldSearch ? [, ...filteredStations] : [],
    };
  }, [shouldSearch, filteredStations, !!lastSearch.length]);

  const handleTo: HandleCityFnc = (city, newAction) => {
    setTo(city);
    onToChange(city, newAction);
    setAnchorElTo(null);
  };

  const handleFrom: HandleCityFnc = (city, newAction) => {
    setFrom(city);
    onFromChange(city, newAction);
    setAnchorElFrom(null);

    // WEB-1989 due to bug on the phone: when both search bars are empty and we select the closest station in From Search Bar
    // arrival destination is also set from favourite destinations
    if (isMobile && isFourNearestStationsOnMobile) {
      handleTo({ label: '', placeType: '', value: '' }, action);
    }
  };

  const setTheClosestStationOnDesktop = (handleDir: 'from' | 'to') => {
    if (closestStations.options[0]) {
      const handleFnc = handleDir === 'to' ? handleTo : handleFrom;
      handleFnc(closestStations.options[0], action);
    }
  };

  const setUserLocation = (handleDir: 'from' | 'to' = 'from') => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setIsGeolocation(true);
          if (isMobile) {
            if (isFourNearestStationsOnMobile) {
              setIsFourNearestStationsOnMobile(!isFourNearestStationsOnMobile);
              return;
            }
            setIsFourNearestStationsOnMobile(!isFourNearestStationsOnMobile);
          }
          if (
            userLongitude !== parseFloat(coords.longitude.toFixed(4)) ||
            userLatitude !== parseFloat(coords.latitude.toFixed(4)) ||
            !closestStations.options.length
          ) {
            setUserLocationToLocalStorage({
              userLatitude: parseFloat(coords.latitude.toFixed(4)),
              userLongitude: parseFloat(coords.longitude.toFixed(4)),
            });
            const fourClosestStations = data
              ?.flatMap((city) =>
                city.stations.map((s) => ({
                  ...s,
                  distance: utils.calculateDistance(
                    s.latitude,
                    s.longitude,
                    coords.latitude,
                    coords.longitude,
                  ),
                })),
              )
              .sort((a, b) => a.distance - b.distance)
              .slice(0, 4)
              .map((st) => ({
                label: st.name,
                value: st.id.toString(),
                placeType: 'STATION',
                vehicleTypes: st.types,
                latitude: st.latitude,
                longitude: st.longitude,
                distance: st.distance,
              }));

            const nearest = {
              label: t('stationDepartures.closeststation'),
              options: fourClosestStations.map((st) => ({ ...st, closestStation: true })),
            };

            setClosestStationsToLocalStorage(nearest);
            if (!isMobile && nearest.options[0]) {
              const handleFnc = handleDir === 'to' ? handleTo : handleFrom;
              handleFnc(nearest.options[0], action);
            }
          } else if (!isMobile && closestStations.options.length) {
            setTheClosestStationOnDesktop(handleDir);
          }
        },
        () => {
          setIsGeolocation(false);
          showModal(<GeolocationNotAllowed />, { zIndex: 1301 });
        },
      );
    }
  };
  /**
   * Tyto funkce zodpovídají za správné otevření/zobrazení/zavření city-select
   */
  const handleOpeningMenuFrom = () => handleSelectFrom(true);
  const handleClosingMenuFrom = () => handleSelectFrom(false);
  const handleOpeningMenuTo = () => handleSelectTo(true);
  const handleClosingMenuTo = () => handleSelectTo(false);

  const swapCities = () => {
    if (from) {
      handleTo(from, action);
      if (!to) handleFrom(null, action);
    }
    if (to) {
      handleFrom(to, action);
      if (!from) handleTo(null, action);
    }
  };

  const handleChange =
    (handleDirection: 'from' | 'to') =>
    (city: OnChangeValue<City, false>, newAction: ActionMeta<CitiesOptionValues>) => {
      if (city.value.startsWith('fav')) {
        handleFrom(mapDestinationToOption(getDestination(city.stationIds.from)), action);
        handleTo(mapDestinationToOption(getDestination(city.stationIds.to)), action);
        return;
      }
      const handleFnc = handleDirection === 'to' ? handleTo : handleFrom;

      return city.value === 'closestOption'
        ? setUserLocation(handleDirection)
        : handleFnc(city, newAction);
    };

  const handleBack = () => {
    setIsFourNearestStationsOnMobile(false);
    setAnchorElFrom(null);
    setAnchorElTo(null);
  };

  useEffect(() => {
    if (query.fromLocationId && query.fromLocationId !== from?.value) {
      const destination = getDestination(+query.fromLocationId);
      if (destination) handleFrom(mapDestinationToOption(destination), action);
    }
    if (query.toLocationId && query.toLocationId !== to?.value) {
      const destination = getDestination(+query.toLocationId);
      if (destination) handleTo(mapDestinationToOption(destination), action);
    }
  }, [query.fromLocationId, query.toLocationId, loading]);

  /**
   * TO-DO: předělat a zjednodušit celou komponentu, pravděpodobně bude nutné odstranit
   * react-select a nahradit- problémy skrz floating label, onMenuOpen, onMenuClose, menuIsOpen,
   * input, cursor
   */
  return !isMobile ? (
    /** Pole Odkud-Kam (desktop) */
    <div className="relative w-auto mx-0.5 flex flex-row justify-center items-center rounded-sm bg-neutral-white">
      <SelectDropdown
        Icon={DestinationFrom}
        data-id="departure-station"
        className={classNames('city-select rounded-sm', isOpenFrom && 'pointer-events-auto')}
        loadingMessage={() => t('accessibility.loader')}
        aria-label={t('arrivalsDepartures.firstStation')}
        placeholder={t('arrivalsDepartures.firstStation')}
        options={filteredStationsDesktop.from}
        // Musíme nastavit filter option jako true, jinak nehledá aliasy
        filterOption={() => true}
        noOptionsMessage={NoOptionMessage}
        value={from}
        onInputChange={(value) => setSearchString(value)}
        components={{ Menu, Option }}
        onChange={handleChange('from')}
        // pokud není touchdevice zobrazit overlay v Searchbox, musí být else undefined
        // jinak nejde otevřít dropdown
        menuIsOpen={!isTouchDevice ? isOpenFrom : undefined}
        onMenuOpen={!isTouchDevice ? handleOpeningMenuFrom : undefined}
        onMenuClose={!isTouchDevice ? handleClosingMenuFrom : undefined}
        isLoading={loading}
      />
      <ClickableDiv
        className="relative px-1 z-10 swap-cities"
        aria-label={t('accessibility.button.reverseDirection')}
        onClick={swapCities}
      >
        <ArrowSyncAlt className="w-3 h-3 fill-neutral-gray2 bg-neutral-white" />
      </ClickableDiv>
      <SelectDropdown
        Icon={LocationOn}
        data-id="arrival-station"
        className={classNames('city-select rounded-sm', isOpenTo && 'pointer-events-auto')}
        loadingMessage={() => t('accessibility.loader')}
        aria-label={t('arrivalsDepartures.lastStation')}
        placeholder={t('arrivalsDepartures.lastStation')}
        options={filteredStationsDesktop.to}
        noOptionsMessage={NoOptionMessage}
        value={to}
        onInputChange={(value) => setSearchString(value)}
        filterOption={() => true}
        components={{ Menu, Option }}
        onChange={handleChange('to')}
        // pokud není touchdevice zobrazit overlay v Searchbox, musí být else undefined
        // jinak nejde otevřít dropdown
        menuIsOpen={!isTouchDevice ? isOpenTo : undefined}
        onMenuOpen={!isTouchDevice ? handleOpeningMenuTo : undefined}
        onMenuClose={!isTouchDevice ? handleClosingMenuTo : undefined}
        isLoading={loading}
      />
    </div>
  ) : (
    /* Pole Odkud-Kam (mobil) */
    <div className="w-full relative lg:flex">
      <ClickableDiv
        className="rounded-t-sm bg-neutral-white flex px-1 h-12 w-full"
        onClick={(e) => setAnchorElFrom(e.target)}
      >
        <div className="flex items-center gap-1">
          <DestinationFrom className="w-2.5 h-2.5 fill-neutral-gray2" />
          <div className="text-14">
            <span className={classNames('clickableDiv-placeholder', from ? 'selected' : 'default')}>
              <Trans i18nKey="arrivalsDepartures.firstStation" />
            </span>
            <span className="clickableDiv-input city">{from?.label}</span>
          </div>
        </div>
      </ClickableDiv>
      <div className="cities-select__delimiter h-px bg-white" />
      <ClickableDiv
        className="absolute z-10 swap-cities"
        aria-label={t('accessibility.button.reverseDirection')}
        onClick={swapCities}
      >
        <ArrowSyncAlt className="w-3 h-3 fill-neutral-gray2 bg-neutral-white" />
      </ClickableDiv>
      <ClickableDiv
        className="rounded-b-sm bg-neutral-white flex px-1 h-12 w-full"
        onClick={(e) => setAnchorElTo(e.target)}
      >
        <div className="flex items-center">
          <LocationOn className="w-2.5 h-2.5 mr-1 fill-neutral-gray2" />
          <div className="text-14">
            <span className={classNames('clickableDiv-placeholder', to ? 'selected' : 'default')}>
              <Trans i18nKey="arrivalsDepartures.lastStation" />
            </span>
            <span className="clickableDiv-input city">{to?.label}</span>
          </div>
        </div>
      </ClickableDiv>
      <Popover
        anchorEl={anchorElFrom}
        anchorReference="none"
        transitionDuration={0}
        open={!!anchorElFrom}
        onClose={() => setAnchorElFrom(null)}
        PaperProps={{ className: 'cssreset-popover-paper' }}
      >
        <PopoverHeader
          i18nKey="arrivalsDepartures.firstStation"
          handleBack={handleBack}
          hasShadow={false}
        />
        <div className="inline-block searchBox-mob-container popover-city">
          <SelectDropdown
            autoFocus
            Icon={DestinationFrom}
            className="city-select border-t rounded-sm"
            loadingMessage={() => t('accessibility.loader')}
            placeholder={
              isFourNearestStationsOnMobile &&
              isGeolocation &&
              !isInputInMobileSearchBar &&
              !from &&
              t('stationDepartures.myLocation')
            }
            options={filteredStationsMob.from}
            noOptionsMessage={NoOptionMessage}
            value={from}
            components={{ Option }}
            onChange={handleChange('from')}
            onInputChange={(input) => {
              setIsInputInMobileSearchBar(!!input.length);
              setSearchString(input);
            }}
            filterOption={() => true}
            isFourNearestStationsOnMobile={isFourNearestStationsOnMobile}
            setIsFourNearestStationsOnMobile={setUserLocation}
            isGeolocationAllowed={isGeolocation}
            menuIsOpen
            isLoading={loading}
          />
        </div>
      </Popover>
      <Popover
        anchorEl={anchorElTo}
        anchorReference="none"
        transitionDuration={0}
        open={!!anchorElTo}
        onClose={() => setAnchorElTo(null)}
        PaperProps={{ className: 'cssreset-popover-paper' }}
      >
        <PopoverHeader
          i18nKey="arrivalsDepartures.lastStation"
          handleBack={handleBack}
          hasShadow={false}
        />
        <div className="inline-block searchBox-mob-container popover-city">
          <SelectDropdown
            autoFocus
            Icon={LocationOn}
            className="city-select border-t rounded-sm"
            loadingMessage={() => t('accessibility.loader')}
            placeholder={
              isFourNearestStationsOnMobile &&
              isGeolocation &&
              !isInputInMobileSearchBar &&
              !to &&
              t('stationDepartures.myLocation')
            }
            options={filteredStationsMob.to}
            filterOption={() => true}
            noOptionsMessage={NoOptionMessage}
            value={to}
            isFourNearestStationsOnMobile={isFourNearestStationsOnMobile}
            onInputChange={(input) => {
              setIsInputInMobileSearchBar(!!input.length);
              setSearchString(input);
            }}
            setIsFourNearestStationsOnMobile={setUserLocation}
            isGeolocationAllowed={isGeolocation}
            components={{ Option }}
            onChange={handleChange('to')}
            menuIsOpen
            isLoading={loading}
          />
        </div>
      </Popover>
    </div>
  );
};

export default CitiesSelect;
