import { isAfter, parseISO, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useLocations, useUser } from '@web/shop-logic';
import { ConnectionPayload } from '@web/shop-logic/dist/hooks/useConnection';
import { Button } from '@/components/atoms/Button';
import Overlay from '@/components/atoms/Overlay';
import Tooltip from '@/components/atoms/Tooltip';
import CitiesSelect from '@/components/organisms/CitiesSelect';
import DatesSelect from '@/components/organisms/DatesSelect';
import TariffSelect from '@/components/organisms/TariffSelect';
import Tariff from '@/components/registration/tariffOptions';
import SearchBoxCompact from '@/components/templates/SearchBoxCompact';
import URLS from '@/constants/url';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ComponentShopFavoriteConnections } from '@/models/types';
import { buildQuery } from '@/utils/routerUtils';

export type SearchQuery = Partial<Omit<ConnectionPayload, 'id'>>;

export const citySelectValues = {
  aliases: [],
  placeType: null,
  value: null,
  vehicleTypes: null,
};

export const getCorrectDate = (date: string | null) => {
  if (!date) {
    return null;
  }
  const parsedDate = parseISO(date);
  const todayStart = startOfDay(utcToZonedTime(new Date(), 'Europe/Prague'));
  return isAfter(parsedDate, todayStart) ? startOfDay(utcToZonedTime(date, 'Europe/Prague')) : null;
};

interface SearchBoxProps {
  showCompactBox?: boolean;
  layoutClassName?: string;
  className?: string;
  setSroTicketsFetching?: (arg: boolean) => void;
  initialValues?: SearchQuery;
  loading?: boolean;
  strapiFavorites?: ComponentShopFavoriteConnections[];
}

const SearchBox: React.FC<SearchBoxProps> = ({
  className,
  layoutClassName,
  showCompactBox,
  setSroTicketsFetching,
  initialValues: providedInitialValues,
  loading,
  strapiFavorites,
}) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { isMobile } = useMobileWidth();
  const { user } = useUser();
  const { getDestination } = useLocations();

  const [openDirection, setOpenDirection] = useState<'from' | 'to' | null>(null);
  const [popperOpen, togglePopper] = useState(false);

  const [showFullBox, setShowFullBox] = useState<boolean>(!showCompactBox);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const isTouchDevice = !!global.window?.ontouchstart;

  const onSubmit = async ({ fromLocationName, toLocationName, ...values }: SearchQuery) => {
    if (!values.fromLocationId || !values.toLocationId) {
      setShowTooltip(true);
    } else {
      setSroTicketsFetching?.(false);
      await push({ pathname: URLS.HOMEPAGE, query: buildQuery({ ...values }) });
    }
  };

  const initialValues: SearchQuery = {
    departureDate: null,
    tariffs: [user?.user?.defaultTariffKey || Tariff.Regular],
    fromLocationId: null,
    fromLocationName: null,
    fromLocationType: null,
    toLocationId: null,
    toLocationName: null,
    toLocationType: null,
  };

  const correctValues = (() => {
    if (!providedInitialValues) return null;

    const fromLocation = getDestination(+providedInitialValues?.fromLocationId);
    const toLocation = getDestination(+providedInitialValues?.toLocationId);

    return {
      from: { ...citySelectValues, label: fromLocation?.name },
      to: { ...citySelectValues, label: toLocation?.name },
      departureDate: getCorrectDate(providedInitialValues?.departureDate),
      returnDepartureDate: getCorrectDate(providedInitialValues?.returnDepartureDate),
    };
  })();

  return (
    <Formik initialValues={providedInitialValues || initialValues} onSubmit={onSubmit}>
      {({
        values: { departureDate, returnDepartureDate, tariffs, fromLocationName, toLocationName },
        setFieldValue,
      }) => (
        <Form>
          {
            /**
             * Overlay se zobrazuje pouze na desktopu, v případě tabletu na šířku komponenta
             * CitiesSelect nefunguje správně skrze touchevent
             */
            !isTouchDevice && (openDirection || popperOpen) && <Overlay />
          }
          <div
            role="search"
            className={classNames('page-layout--desktop overlay-shadow', layoutClassName)}
          >
            {showCompactBox && !showFullBox && (
              /**
               * Compact Box
               * Mobile: Zobrazeno na stránce Výsledky vyhledávání
               * Desktop: vždy skryto
               * Po kliknutí na něj se otevře Full Box a Compact Box se skryje (nelze vrátit zpět)
               */
              <SearchBoxCompact
                fromLocation={fromLocationName}
                toLocation={toLocationName}
                departureDate={departureDate}
                returnDepartureDate={returnDepartureDate}
                passengers={t('searchbox.compact.passengers', { count: tariffs?.length || 1 })}
                setShowFullBox={setShowFullBox}
              />
            )}
            {/**
             * Full Box
             * Mobile: skryto na stránce Výsledky vyhledávání
             * Desktop: vždy zobrazeno
             */}
            <div
              className={classNames(
                'relative z-30 lg:w-full max-w-full px-2 pb-2 flex flex-col lg:flex-row justify-between items-center bg-primary-yellow lg:rounded-md place-self-center',
                className,
                !showFullBox && 'sm:hidden',
              )}
            >
              <Tooltip
                title={t('searchbox.tooltip.missingCities')}
                open={!isMobile && showTooltip}
                placement="top"
              >
                <div className="sm:w-full">
                  <CitiesSelect
                    onFromChange={(value) => {
                      if (value) {
                        setFieldValue('fromLocationId', parseInt(value.value, 10));
                        setFieldValue('fromLocationName', value.label);
                        setFieldValue('fromLocationType', value.placeType);
                      }
                    }}
                    onToChange={(value) => {
                      if (value) {
                        setFieldValue('toLocationId', parseInt(value.value, 10));
                        setFieldValue('toLocationName', value.label);
                        setFieldValue('toLocationType', value.placeType);
                      }
                    }}
                    handleSelectFrom={(open) => setOpenDirection(open ? 'from' : null)}
                    handleSelectTo={(open) => setOpenDirection(open ? 'to' : null)}
                    isOpenFrom={openDirection === 'from'}
                    isOpenTo={openDirection === 'to'}
                    defaultValues={{ from: correctValues?.from, to: correctValues?.to }}
                    strapiFavorites={strapiFavorites}
                  />
                </div>
              </Tooltip>
              <DatesSelect
                className="date-select sm:w-full sm:my-1.5 lg:mx-0.5"
                setDepartureDate={(date) => setFieldValue('departureDate', date)}
                setReturnDepartureDate={(date) => setFieldValue('returnDepartureDate', date)}
                defaultDepartureDate={correctValues?.departureDate || null}
                defaultArrivalDate={correctValues?.returnDepartureDate || null}
                handleOverlay={togglePopper}
              />
              <TariffSelect
                onTariffsChanged={(newTariffs) => setFieldValue('tariffs', newTariffs)}
                onInteracted={togglePopper}
              />
              <div className="sm:w-full sm:mt-1.5 lg:mx-0.5">
                <Button
                  data-id="search-btn"
                  className="sm:w-full"
                  size="big"
                  type="submit"
                  disabled={loading}
                >
                  <Trans i18nKey="search.title" />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchBox;
