import { Enum_Componentpageiconbox_Iconcolor } from '@/models/types';

export const mapBackgroundToComponent = {
  white: 'bg-neutral-white',
  yellow: 'bg-primary-yellow',
  bluelight: 'bg-primary-bluelight3',
  graylight: 'bg-neutral-graylight',
  yellowlight: 'bg-primary-yellowlight4',
  bluelight1: 'bg-primary-bluelight',
  yellowlight1: 'bg-primary-yellowlight',
};

export const mapBackgroundToIcon: Record<Enum_Componentpageiconbox_Iconcolor, string> = {
  default: 'bg-primary-bluelight2',
  gray: 'bg-neutral-graylight',
  green: 'bg-secondary-greenlight2',
  orange: 'bg-primary-yellowlight4',
  red: 'bg-secondary-rose',
  yellow: 'bg-primary-yellowlight4',
};

export const mapColorToIcon: Record<Enum_Componentpageiconbox_Iconcolor, string> = {
  default: 'text-primary-blue',
  gray: 'text-neutral-gray',
  green: 'text-secondary-green',
  orange: 'text-secondary-orange',
  red: 'text-secondary-red',
  yellow: 'text-primary-yellow',
};
