import SearchBox, { SearchQuery } from '../templates/SearchBox';
import React from 'react';
import classNames from 'classnames';
import { ButtonLink } from '@/components/atoms/Button';
import StrapiImage from '@/components/content/StrapiImage';
import RichParagraph from '@/components/strapi/RichParagraph';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ComponentPageHeroBanner } from '@/models/types';
import { getSearchQueryFromString } from '@/utils/routerUtils';
import { mapBackgroundToComponent } from '@/utils/strapiUtils';

interface PageHeroBannerProps {
  component: ComponentPageHeroBanner;
}
const PageHeroBanner: React.FC<PageHeroBannerProps> = ({ component }) => {
  const { isMobile } = useMobileWidth();

  if (!component) return null;

  const {
    bannerDesktop,
    bannerMobile,
    header,
    subheader,
    button,
    image,
    backgroundColor,
    searchBar,
    fullWidth,
  } = component;

  const background = mapBackgroundToComponent[backgroundColor];
  const queryValues = searchBar?.queryValues;

  const strapiQuery: SearchQuery = queryValues ? getSearchQueryFromString(queryValues) : undefined;

  return (
    <>
      <div
        className={classNames(
          background,
          'relative',
          !fullWidth && 'page-layout--desktop lg:rounded-md',
        )}
      >
        <div className={classNames(fullWidth ? 'lg:h-[400px] fullhd:h-[600px]' : 'lg:h-auto')}>
          <StrapiImage
            loading="eager"
            image={isMobile ? bannerMobile : bannerDesktop}
            alt={header}
          />
        </div>
        <div className="page-layout sm:pb-4">
          <div className="lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:w-[425px] sm:text-center">
            {header && <h1 className="text-headline leading-tight mt-2">{header}</h1>}
            {subheader && <RichParagraph text={subheader} />}
            {button?.url && button?.text && (
              <ButtonLink href={button?.url} target="_blank">
                {button?.text}
              </ButtonLink>
            )}
            {image && (
              <StrapiImage loading="eager" className="sm:mx-auto" image={image} alt={header} />
            )}
          </div>
        </div>
      </div>

      {searchBar && <SearchBox initialValues={strapiQuery} className="lg:-mt-5 pt-2" />}
    </>
  );
};

export default PageHeroBanner;
