import { Trans } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import modalContext from '@/components/modal/ModalContext';
import useContextSelector from '@/hooks/useContextSelector';

const GeolocationNotAllowed = () => {
  const hideAllModals = useContextSelector(modalContext, (c) => c.hideAllModals);

  return (
    <div className="flex flex-col">
      <h2 className="h2">
        <Trans i18nKey="geolocation.not.allowed.headline" />
      </h2>
      <span className="my-2">
        <Trans i18nKey="geolocation.not.allowed" />
      </span>
      <div>
        <div className="flex justify-end">
          <Button className="sm:w-full" size="big" onClick={hideAllModals}>
            <Trans i18nKey="ticket.passengerConfirmationModal.button.understand" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeolocationNotAllowed;
