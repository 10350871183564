import { Trans } from 'next-i18next';
import React, { ReactElement, useEffect, useRef } from 'react';
import { components, MenuProps, OptionProps } from 'react-select';
import OptionBase from 'react-select';
import classNames from 'classnames';
import { LocationType, StationType } from '@web/shop-logic/dist/hooks/useLocations';
import useMobileWidth from '@/hooks/useMobileWidth';
import { DirectionsBus, DirectionsTrain, Emptysearch, Gps, LocationOn } from '@/icons';
import { NearestStationsProps } from '@/utils/citiesSelectUtils';

export interface CitiesOptionData extends OptionBase {
  closestStation?: NearestStationsProps;
  distance?: number;
  getLocationTitle?: string;
  placeType: LocationType;
  vehicleTypes: StationType[];
  aliases: string[];
  value?: string;
  label?: string;
}

const getMessageKey = (inputValue: string): string => {
  switch (true) {
    case !inputValue.length:
      return 'searchbox.city.whichDestination';
    case inputValue.length < 3:
      return 'searchbox.minimum.length';
    default:
      return 'searchbox.city.not.found';
  }
};

export const NoOptionMessage = ({ inputValue }): ReactElement<{ inputValue: string }> => {
  const messageKey = getMessageKey(inputValue);

  return (
    <div className="sm:text-black">
      <Emptysearch className="lg:hidden w-52 h-52 flex-shrink-0 mx-auto" />
      <p>
        <Trans i18nKey={messageKey} />
      </p>
    </div>
  );
};

export const Option = ({ data, children, ...props }: OptionProps<CitiesOptionData, false>) => {
  /**
   * Čtečky na mobilních OS (Talkback) špatně handlují potvrzení Optionu
   * Komponentu `<components.Option />` nemůžeme přepsat, proto ji obalujeme do vlastního DIVu
   * Ten zachytí čtečkové kliknutí a spustí onChange()
   *
   * Zároveň NECHCEME, aby se DIV choval jako ClickableDiv - tabIndexy
   * a další keyboard handlery už řeší `react-select`, proto jsou vypnuty
   *
   * Přidání atributu `onClick` přímo na DIV nefunguje, proto se používá REF a listener
   * se přidává až po vyrenderování do DOMu
   */
  const { isMobile } = useMobileWidth();
  const optionRef = useRef<HTMLDivElement>(null);

  const handleClick = () =>
    props?.selectProps?.onChange(data, { action: 'select-option', option: undefined });

  useEffect(() => {
    if (isMobile) {
      optionRef?.current?.addEventListener('click', handleClick, true);
    }
    return () => {
      optionRef?.current?.removeEventListener('click', handleClick);
    };
  }, [isMobile, data]);

  return (
    <div ref={optionRef}>
      <components.Option {...props} data={data}>
        <div className="flex menu-row">
          {data.placeType === 'CITY' && <LocationOn className="w-2.5 h-2.5 mr-1" />}
          {data.placeType === 'STATION' && data.vehicleTypes.includes('BUS_STATION') && (
            <DirectionsBus className="w-2.5 h-2.5 ml-2 mr-1" />
          )}
          {data.placeType === 'STATION' && data.vehicleTypes.includes('TRAIN_STATION') && (
            <DirectionsTrain
              className={classNames('w-2.5 h-2.5 mr-1', data.vehicleTypes.length < 2 && 'ml-2')}
            />
          )}

          {data.value === 'closestOption' || data.closestStation ? (
            <div className="flex">
              <div className="self-center sm:hidden">
                <Gps className="w-2.5 h-2.5 mr-1" />
              </div>
              <div className="flex flex-col">
                <span>{data.label}</span>
                <div className="text-12">
                  {isMobile ? (
                    <Trans
                      i18nKey="citiesSelect.distance.from.you"
                      values={{ distance: data.distance?.toFixed(2) }}
                    />
                  ) : (
                    data.getLocationTitle
                  )}
                </div>
              </div>
            </div>
          ) : (
            data.label
          )}
        </div>
      </components.Option>
    </div>
  );
};

export const Menu = ({ children, ...props }: MenuProps<CitiesOptionData, false>) => (
  <components.Menu {...props} className="menu">
    {children}
  </components.Menu>
);
