import { TFunction } from 'next-i18next';
import { ActionMeta } from 'react-select';
import {
  ConnectionSearchCity,
  ConnectionSearchStation,
  StationType,
} from '@web/shop-logic/dist/hooks/useLocations';
import { ComponentShopFavoriteConnections } from '@/models/types';

export interface DefaultValuesCitiesSelect {
  from: City;
  to: City;
}

export interface CitiesOptionValues {
  label: string;
  value: string;
  stationIds?: {
    from: number;
    to: number;
  };
}

export interface City extends CitiesOptionValues {
  placeType: string;
}

export interface StationProps extends City {
  latitude: number;
  longitude: number;
  distance?: number;
  vehicleTypes?: StationType[];
}

export type HandleCityFnc = (value: City | null, action: ActionMeta<CitiesOptionValues>) => void;

export interface CitySelectProps {
  handleSelectFrom: (opened: boolean) => void;
  handleSelectTo: (opened: boolean) => void;
  isOpenFrom: boolean;
  isOpenTo: boolean;
  onFromChange: HandleCityFnc;
  onToChange: HandleCityFnc;
  defaultValues?: DefaultValuesCitiesSelect;
  strapiFavorites?: ComponentShopFavoriteConnections[];
}

export interface NearestStationsProps {
  label: string;
  options: StationProps[];
}

export const getFavoriteOptions = (t: TFunction, loading: boolean) => ({
  label: t('station.favourite.connection.header'),
  options: loading
    ? []
    : [
        {
          label: t('station.favourite.connection1'),
          placeType: 'CITY',
          value: 'fav0',
        },
        {
          label: t('station.favourite.connection2'),
          placeType: 'CITY',
          value: 'fav1',
        },
        {
          label: t('station.favourite.connection3'),
          placeType: 'CITY',
          value: 'fav2',
        },
        {
          label: t('station.favourite.connection4'),
          placeType: 'CITY',
          value: 'fav3',
        },
      ],
});

export const mapDestinationToOption = (
  location: ConnectionSearchStation | ConnectionSearchCity,
) => ({
  aliases: location?.aliases,
  label: location?.name,
  placeType: location?.placeType,
  value: location.id.toString(),
  vehicleTypes: (location as ConnectionSearchStation).types,
});
