import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import Select, {
  components as SelectComponents,
  ControlProps,
  ValueContainerProps,
  Props as SelectProps,
} from 'react-select';
import classNames from 'classnames';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import useMobileWidth from '@/hooks/useMobileWidth';
import { Clear, Gps, IconElement } from '@/icons';

const CustomValueContainer: React.FC<ValueContainerProps<SelectProps, false>> = ({
  children,
  ...props
}) => (
  <SelectComponents.ValueContainer {...props}>
    {/*
      // @ts-expect-error Props override  */}
    <SelectComponents.Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </SelectComponents.Placeholder>
    {React.Children.map(children, (child) =>
      // @ts-expect-error Simplify object check
      child && child?.type !== SelectComponents.Placeholder ? child : null,
    )}
  </SelectComponents.ValueContainer>
);

/**
 * Úprava komponenty Control (WEB-1903)
 * 1. Všechny komponenty, které se posílají do `<Select components={} >`,
 *    musí být mimo render funkci, jinak se v průběhu přerenderují a ztratí focus
 * 2. Abychom na tomto místě měli přístup k Icon, musíme si ji poslat do Selectu
 *    přes nově založenou prop `customIcon`
 *
 * Zdroje:
 * https://github.com/JedWatson/react-select/issues/3575
 * https://github.com/JedWatson/react-select/issues/2810#issuecomment-590286357
 */
interface CustomSelectProps {
  Icon?: IconElement;
  isFourNearestStationsOnMobile?: boolean;
  onGps: () => void;
  isGeolocationAllowed?: boolean;
}

const createControl: (customProps: CustomSelectProps) => React.FC<ControlProps<SelectProps>> = ({
  isFourNearestStationsOnMobile,
  onGps,
  Icon,
  isGeolocationAllowed,
}) =>
  function CustomControl({ children, ...controlProps }) {
    const { isMobile } = useMobileWidth();

    return (
      <div className="relative">
        <SelectComponents.Control {...controlProps}>
          <span className="select-icon">
            <Icon />
          </span>
          {children}
        </SelectComponents.Control>
        {isMobile && onGps && (
          /**
           * right-[17px] kvůli viditelnosti borderu na pravé straně při použití background
           */
          <ClickableDiv
            className="pr-1 w-4 h-4 absolute right-[17px] top-1.5 z-60 bg-neutral-white"
            onClick={onGps}
          >
            <span className="closest-stations-icon">
              {isFourNearestStationsOnMobile && isGeolocationAllowed ? <Clear /> : <Gps />}
            </span>
          </ClickableDiv>
        )}
      </div>
    );
  };

interface SelectDropdownProps extends SelectProps {
  Icon?: IconElement;
  setIsFourNearestStationsOnMobile?: () => void;
  isFourNearestStationsOnMobile?: boolean;
  isGeolocationAllowed?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  Icon,
  children,
  className,
  setIsFourNearestStationsOnMobile,
  isFourNearestStationsOnMobile,
  isGeolocationAllowed,
  components,
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();

  const getMarginBottomOfPlaceHolder = (placeholderProps) => {
    if ((placeholderProps.hasValue || placeholderProps.selectProps.inputValue) && isMobile)
      return '18px';
    if ((placeholderProps.hasValue || placeholderProps.selectProps.inputValue) && !isMobile)
      return '26px';
    return '0px';
  };

  const Control = useMemo(
    () =>
      createControl({
        isFourNearestStationsOnMobile,
        onGps: setIsFourNearestStationsOnMobile,
        Icon,
        isGeolocationAllowed,
      }),
    [isFourNearestStationsOnMobile, Icon, isGeolocationAllowed],
  );

  return (
    <Select
      className={classNames('select-dropdown', className)}
      classNamePrefix="react-select"
      ariaLiveMessages={{
        guidance: (guidanceProps) =>
          t('accesibility.cities.cityGuidance', {
            ariaLabel: guidanceProps?.['aria-label'] || '',
          }),
        onFilter: ({ inputValue, resultsMessage }) => {
          // Nutno splitnout protoze vychozi hodnota je `20 results available`
          const resultsCount = resultsMessage.split(' ')[0];
          return t('accesibility.cities.resultsCount', { inputValue, resultsCount });
        },
        onFocus: ({ label }) => t('accesibility.cities.selectedValue', { label }),
      }}
      styles={{
        valueContainer: (styles) => ({ ...styles, overflow: 'visible' }),
        control: (styles) => ({
          ...styles,
          border: '1px solid #C0D2DB',
          borderRadius: 6,
          cursor: 'pointer',
        }),
        placeholder: (styles, placeholderProps) => ({
          ...styles,
          display: 'initial',
          marginBottom: getMarginBottomOfPlaceHolder(placeholderProps),
          width: '100%',
          top:
            // eslint-disable-next-line no-nested-ternary
            placeholderProps.isMulti && placeholderProps.hasValue
              ? 8
              : placeholderProps.isFocused ||
                placeholderProps.hasValue ||
                placeholderProps.selectProps.inputValue
              ? 10
              : '50%',
          transition: 'top 0.1s, font-size 0.1s',
          fontSize:
            (placeholderProps.isFocused ||
              placeholderProps.hasValue ||
              placeholderProps.selectProps.inputValue) &&
            12,
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          color: isFocused ? '#00519E' : undefined,
          backgroundColor: isFocused ? '#EDF6FF' : undefined,
          borderRadius: 6,
          cursor: 'pointer',
        }),
        multiValue: (styles) => ({
          ...styles,
          backgroundColor: '#00519E',
          borderRadius: 6,
          color: '#FFFFFF',
          cursor: 'pointer',
        }),
        multiValueLabel: (styles) => ({ ...styles, color: 'white', fontSize: '100%' }),
        multiValueRemove: (styles) => ({
          ...styles,
          ':hover': { backgroundColor: 'black', color: 'white' },
        }),
        singleValue: (styles) => ({ ...styles, fontSize: 14, margin: '8px 2px 2px 2px' }),
      }}
      components={{
        ValueContainer: CustomValueContainer,
        Control,
        ...(components || {}),
      }}
      {...props}
    />
  );
};

export default SelectDropdown;
